import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Skeleton,
    Typography,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    Select,
    MenuItem,
    SelectChangeEvent,
    TextField
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";

import { useCallback, useContext, useState, useEffect, useMemo } from "react";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Cancel, DeleteForever, SelectAll } from "@mui/icons-material";

import useDataClient from "../../axios/dataClient";
import { useAsync } from "../../useAsync";

import { MemberAppContext } from "../../MemberAppContext";

import { error } from "../ErrorDisplay";

import { FolderCard } from "./folderCard";
import { FolderBackButton } from "./FolderBackButton";
import { File, FileProps } from "./file";
import { CreateFolderDialog } from "./createFolder";
import { UploadAssetDialog } from "./uploadAsset";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

export interface FolderInfo {
    path: string;
    name: string;
    isPublic: boolean;
    subfolders: string[];
    files: FileProps[];
}

const splitPath = (fullPath?: string): { path: string; name: string; isPublic: boolean } => {
    if (fullPath === undefined) {
        return { path: "", name: "", isPublic: false };
    }

    const segments = fullPath.split("/");
    if (segments.length === 0) {
        return { path: "", name: "", isPublic: false };
    }

    // There is an empty segment, as location.pathname always starts with a leading slash
    return {
        path: segments.map(segment => decodeURI(segment)).slice(3).join("/"),
        name: decodeURI(segments[segments.length - 1]),
        isPublic: segments[2] === "public"
    };
};

export interface BlobFile {
    name: string;
    path: string;
    url: string;
    lastUpdated?: Date | string;
}

export const Folder = () => {
    const navigate = useNavigate();
    const appContext = useContext(MemberAppContext);
    const location = useLocation();
    const { path, name, isPublic } = splitPath(location.pathname);
    const [contents, setContents] = useState<{ subfolders: { name: string; friendlyName: string  }[]; files: BlobFile[] }>({ subfolders: [], files: [] });
    const { post, get, deleteRequest } = useDataClient();
    const [isLoading, setIsLoading] = useState(false);
    const [createFolderOpen, setCreateFolderOpen] = useState(false);
    const [uploadAssetOpen, setUploadAssetOpen] = useState(false);
    const [friendlyPageName, setFriendlyPageName] = useState<string>(path);
    const [isMultipleDeleting, setIsMultipleDeleting] = useState(false);
    const [runningIsMultipleDeleting, setRunningIsMultipleDeleting] = useState(false);
    const [filesToDelete, setFilesToDelete] = useState<BlobFile[]>([]);
    const [currentlyDisplayedFileCount, setCurrentlyDisplayedFileCount] = useState(24);
    const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);

    useEffect(() => {
        document.title = 'Beebot AI - Media Gallery';
    },[]);

    const fetchPartnerData = useCallback(async () => {
        const partnerData = await get<string>(`/api/asset/partner/${name}`);
        if (partnerData) {
            return partnerData;
        }
        return name;
    }, [name, get]);

    useEffect(() => {
        fetchPartnerData().then((partnerName) => {
            if (partnerName) {
                setFriendlyPageName(partnerName);
            }

            return;
        });
    }, [name, fetchPartnerData]);

    const fetchFolders = useCallback(async () => {
        setIsLoading(true);
        setContents({ subfolders: [], files: [] });
        const formData = new FormData();
        formData.append("folderPath", path);
        formData.append("isPublic", isPublic.toString());
        const value = await post<{ subfolders: any[]; files: any[] }>("api/asset", formData);
        setIsLoading(false);
        return value;
    }, [post, path, isPublic]);

    useAsync(fetchFolders, setContents, []);

    const openCreateFolder = useCallback(() => {
        setCreateFolderOpen(true);
    }, []);

    const closeCreateFolder = useCallback(() => {
        setCreateFolderOpen(false);
    }, []);

    const createFolder = useCallback((newFolderName: string) => {
        setCreateFolderOpen(false);
        const subfolders = [...contents.subfolders, { name: newFolderName, friendlyName: newFolderName }];
        setContents({ subfolders, files: contents.files });
    }, [contents]);

    const openUploadAsset = useCallback(() => {
        setUploadAssetOpen(true);
    }, []);

    const closeUploadAsset = useCallback(() => {
        setUploadAssetOpen(false);
    }, []);

    const uploadAsset = useCallback((newFiles: BlobFile[]) => {
        const allFiles = [...newFiles, ...contents.files];
        setContents({ ...contents, files: allFiles });
        setUploadAssetOpen(false);
    }, [contents]);

    const fileEdited = useCallback((newFile: BlobFile, oldFile: BlobFile) => {
        const fileIndex = contents.files.findIndex(file => file.name === oldFile.name);
        if (fileIndex !== -1) {
            const newFiles = [...contents.files];
            newFiles.splice(fileIndex, 1, newFile);
            setContents({ ...contents, files: newFiles });
        }
    }, [contents]);

    const fileDeleted = useCallback((fileName: string) => {
        const fileIndex = contents.files.findIndex(file => file.name === fileName);
        if (fileIndex !== -1) {
            const newFiles = [...contents.files];
            newFiles.splice(fileIndex, 1);
            setContents({ ...contents, files: newFiles });
        }
    }, [contents]);

    const startMultipleDelete = useCallback(() => {
        setIsMultipleDeleting(true);
    }, []);

    const cancelMultipleDelete = useCallback(() => {
        setFilesToDelete([]);
        setIsMultipleDeleting(false);
    }, []);

    const selectAllOnPage = useCallback(() => {
        setFilesToDelete(contents.files.slice(0, currentlyDisplayedFileCount));
    }, [contents, currentlyDisplayedFileCount]);

    const toggleForDeletion = useCallback((file: BlobFile, isCurrentlySelected: boolean) => {
        if (isCurrentlySelected) {
            setFilesToDelete(current => current.filter(currentFile => currentFile !== file));
        }
        else {
            setFilesToDelete(current => [...current, file]);
        }
    }, []);

    const completeMultipleDelete = useCallback(async () => {

        setRunningIsMultipleDeleting(true);

        const pageNamesWithLinks = await post<string[]>("api/asset/validateMediaUse", {
            urls: filesToDelete.map(file => file.url)
        });

        if (pageNamesWithLinks.length > 0) {
            for (const pageNameWithLinks of pageNamesWithLinks) {
                error(`Cannot delete files as some of them are included in page '${pageNameWithLinks}'`);
            }

            setRunningIsMultipleDeleting(false);
            return;
        }

        const deletedFiles = await deleteRequest<{ successfulFiles: string[]; errors: string[] }>("api/asset/multipleDelete", {
            fileNames: filesToDelete.map(file => file.name),
            isPublic,
            filePath: path
        });
        for (const errorString of deletedFiles.errors) {
            error(`failed to delete file ${errorString}`);
        }
        setContents({ ...contents, files: contents.files.filter(file => !deletedFiles.successfulFiles.includes(file.name)) });
        setFilesToDelete([]);
        setRunningIsMultipleDeleting(false);
        setIsMultipleDeleting(false);
    }, [deleteRequest, filesToDelete, contents, isPublic, path, post]);

    const loadMore = useCallback(() => {
        setCurrentlyDisplayedFileCount(current => current + 24);
    }, []);

    const fileTypes = ["All", "png", "PNG", "jpg", "JPG", "jpeg", "mp3", "mp4", "pdf", "svg", "gif", "ico", "mpeg", "csv", "json"];

    const handleFileTypeChange = (event: SelectChangeEvent<string>) => {
        setSelectedFileType(event.target.value as string);
    };

    const FilterPanel = () => (
        <Box sx={{ marginBottom: "1rem" }}>
            <Select
                value={selectedFileType || "All"}
                onChange={handleFileTypeChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Filter by File Type' }}
            >
                {fileTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, []);

    const SearchField = useMemo(() => (
        <Box sx={{ marginBottom: "1rem" }}>
            <TextField
                label="Search by File Name"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
            />
        </Box>
    ), [searchQuery, handleSearchChange]);

    const filteredFiles = contents.files.filter(file =>
        (selectedFileType && selectedFileType !== "All" ? file.name.endsWith(`.${selectedFileType}`) : true) &&
        (searchQuery ? file.name.toLowerCase().includes(searchQuery.toLowerCase()) : true)
    );

    if (appContext.user.isPartnerUser && path==="") {
        navigate("/assets");
    }

    return (
        <FavoriteProvider>
            <Container>
                <Box>
                    <Box sx={{ marginTop: '1.2rem!important', marginBottom: '.6rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Box className="bb-title-bar" sx={{ marginBottom: '4px' }}>
                            <h2 className="bb-m0 bb-p0">Media Library: {friendlyPageName}</h2>
                            <FavoriteButton page={`assets/public`} displayName={`Media Library`} displayModule={`View your Assets`} />
                        </Box>
                        <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }} >
                            <Box>
                                <FolderBackButton name=".." parentPath={path} isPublic={isPublic} />
                            </Box>
                        </Box>
                    </Box>
                    <Alert className="bb-title-info" severity="info" sx={{ marginBottom: '.8rem' }}>
                      Welcome to the <b>Media Management Page</b>, Here you can manage all of your <b>Folders & Files</b>.
                    </Alert>
                    {isLoading ? <div className="bb-tac"><CircularProgress /></div> :
                        <Box>
                            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)' }}>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", marginBottom: '.6rem' }}>
                                    <Typography variant="h3">Folder Manager</Typography>
                                </Box>
                                <Accordion
                                    sx={{
                                        marginBottom: ".6rem",
                                        borderRadius: "5px",
                                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
                                        '&::before': { display: 'none' }
                                    }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ borderRadius: "5px 5px 0px 0px", backgroundColor: "#25454d", color: "#ffffff" }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <FolderOpenOutlinedIcon sx={{ marginRight: '5px' }} />
                                            <Typography variant="h3" sx={{ margin: '0px!important' }}>{friendlyPageName}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                            marginTop: '.6rem',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                            maxHeight: '360px'
                                        }}>
                                            {contents.subfolders.map(subfolder => (
                                                <Grid key={subfolder.name} item xs={12} sm={12} md={6} lg={2} xl={2} sx={{ margin: "10px" }}>
                                                    <Box sx={{ width: 200 }}>
                                                        <FolderCard
                                                            name={subfolder.name}
                                                            friendlyName={subfolder.friendlyName}
                                                            parentPath={path}
                                                            isPublic={isPublic}
                                                        />
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: "center", marginTop: '1rem' }}>
                                            <Button onClick={openCreateFolder} variant="contained">
                                                <CreateNewFolderOutlinedIcon sx={{ marginRight: '5px' }}/>Add Folder</Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)' }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="h3">Media Manager</Typography>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: '1rem' }}>
                                        <Box>
                                            {!isMultipleDeleting ?
                                                <IconButton onClick={startMultipleDelete} sx={{ padding: '0px!important', marginRight: '10px' }} >
                                                    <Box>
                                                        <Button variant="outlined" size="small"
                                                            sx={{
                                                                color: '#000000!important',
                                                                backgroundColor: '#ffffff!important',
                                                                border: '1px solid #11a681'
                                                            }}>
                                                            <CheckBoxOutlineBlankIcon sx={{ marginRight: '5px', color: "#00000!important" }} />
                                                            <Typography className="bb-media-label">Select</Typography>
                                                        </Button>
                                                    </Box>
                                                </IconButton> :
                                                <Box>
                                                    <IconButton onClick={cancelMultipleDelete} sx={{ padding: '0px!important', marginRight: '10px' }}>
                                                        <Button variant="outlined" size="small"><Cancel />
                                                            <Typography className="bb-media-label"> Cancel</Typography></Button>
                                                    </IconButton>
                                                    <IconButton onClick={selectAllOnPage} sx={{ padding: '0px!important', marginRight: '10px' }}>
                                                        <Button variant="outlined" size="small"><SelectAll />
                                                            <Typography className="bb-media-label"> Select All</Typography>
                                                        </Button>
                                                    </IconButton>
                                                    <IconButton onClick={completeMultipleDelete} sx={{ padding: '0px!important', marginRight: '10px' }}>
                                                        <Button variant="outlined" color="error" size="small"><DeleteForever />
                                                            <Typography className="bb-media-label"> Delete</Typography></Button>
                                                    </IconButton>
                                                </Box>}
                                        </Box>
                                        <Box>
                                            <Button onClick={openUploadAsset} variant="contained">
                                                <CloudUploadOutlinedIcon sx={{ marginRight: '10px' }}/>Upload File</Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                    }}>
                                    <Box sx={{ marginRight: '14px' }}>
                                        <FilterPanel />
                                    </Box>
                                    <Box>
                                        {SearchField}
                                    </Box>
                                </Box>
                                <CreateFolderDialog open={createFolderOpen} onCancel={closeCreateFolder} onConfirm={createFolder} />
                                <Grid container spacing={2}>
                                    {filteredFiles.slice(0, currentlyDisplayedFileCount).map(file =>
                                        runningIsMultipleDeleting && filesToDelete.includes(file) ?
                                            <Grid xs={12} sm={6} md={4} lg={3} xl={2} key={file.name} item>
                                                <Skeleton variant="rectangular" width={210} height={118} />
                                            </Grid> :
                                            (<File key={file.name} file={file} isPublic={isPublic} isMultipleDeleting={isMultipleDeleting}
                                                isBeingDeleted={filesToDelete.includes(file)}
                                                toggleForDeletion={toggleForDeletion}
                                                onSuccessfulEdit={fileEdited}
                                                onSuccessfulDelete={fileDeleted} />))}
                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: "center", marginTop: '1rem' }}>
                                    <Button onClick={loadMore}>Load More</Button>
                                </Box>
                            </Box>
                        </Box>
                    }
                    <UploadAssetDialog open={uploadAssetOpen} filePath={path} isPublic={isPublic} isSingleFile={false} onCancel={closeUploadAsset}
                        onConfirm={uploadAsset} existingAssets={contents.files} />
                </Box>
            </Container>
        </FavoriteProvider>
    );
};
