import { Button, Dialog, DialogActions, DialogContent, TextField, Typography, Box } from "@mui/material";
import { useCallback, useRef } from "react";

interface IntegrationDialogProps {
    isOpen: boolean;
    onCancel: () => void;
    onSaveSuccess: () => void;
}

export const IntegrationDialog = ({ isOpen, onCancel, onSaveSuccess }: IntegrationDialogProps) => {
    const clientName = useRef({ value: "" });
    const clientIdRef = useRef({ value: "" });
    const clientSecretRef = useRef({ value: "" });

    const onSave = useCallback(() => {
        onSaveSuccess();
    }, [onSaveSuccess]);

    return (
        <Dialog open={isOpen}>
            <DialogContent>
                <Box sx={{ marginTop: '10px' }}>
                    <Typography variant="h3">Add Integration</Typography>
                </Box>
                <TextField inputRef={clientName} label="Integration Name" fullWidth margin="normal" />
                <TextField inputRef={clientIdRef} label="Client Id" fullWidth margin="normal" />
                <TextField inputRef={clientSecretRef} label="Client Secret" fullWidth margin="normal" />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onSave} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};
