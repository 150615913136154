import React, { useState, useCallback } from 'react';
import { Card, CardActionArea, Divider, Modal, Box, Grid, Avatar, Typography, CardContent, Button, Alert } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CloseIcon from "@mui/icons-material/Close";
import { FolderCard } from './folderCard';
import { PartnerFolderDirectoryList } from ".";
import useDataClient from "../../axios/dataClient";
import { useAsync } from "../../useAsync";

export const PartnerFolderCard = () => {
    const { get } = useDataClient();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [partnerDirectories, setPartnerDirectories] = useState<PartnerFolderDirectoryList[]>([]);

    const fetchPartnerFolders = useCallback(async () => {
        const value = await get<PartnerFolderDirectoryList[]>("api/asset/partnerFolders");
        return value;
    }, [get]);

    useAsync(fetchPartnerFolders, setPartnerDirectories, []);

    return (
        <>
            <Card className="bb-app-icon">
                <CardActionArea
                    onClick={handleOpen}
                    sx={{ flexGrow: 1 }}>
                    <Box
                        className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                        <Avatar
                            sx={{
                                backgroundColor: 'transparent',
                                padding: '2rem' }}>
                            <GroupsOutlinedIcon />
                        </Avatar>
                    </Box>
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h2"
                            fontWeight="bold"
                            component="div"
                            sx={{ textAlign: 'center' }}>
                            Partners
                        </Typography>
                        <Typography
                            variant="body2"
                            className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                            Access & Manage your Partner Assets
                        </Typography>
                    </CardContent>
                    <Divider />
                </CardActionArea>
            </Card>
            <Modal open={open} onClose={handleClose}>
                <Box
                    className="bb-edit-page-modal"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24, p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '80vh'
                    }}>
                    <Box
                        className="bb-pe-modal-header"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2
                        }}>
                        <Typography
                            variant="h2"
                            component="h2"
                        >
                            Your Partners
                        </Typography>
                        <Box>
                            <Button
                                className="bb-ml-auto"
                                onClick={handleClose}
                                variant="text"
                            >
                                <CloseIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Box className="bb-pe-modal-form">
                        <Alert className="bb-title-info bb-mb-2" severity="info">
                         Here you will find a list of <b>Partner Folders</b> containing all of your Partners <b>Media Assets</b>.
                        </Alert>
                        <Grid container spacing={2}>
                            {partnerDirectories.map((directory) => (
                                <Grid
                                    item xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    key={directory.path}
                                    sx={{
                                        textAlign: 'center'
                                    }}>
                                    <FolderCard
                                        name={directory.path}
                                        friendlyName={directory.name}
                                        parentPath=""
                                        isPublic={true}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <Box
                        className="bb-pe-modal-footer"
                    >
                        <Grid
                            container spacing={1}
                            className="bb-flex bb-fixed"
                        >
                            <Grid item>
                                <Button onClick={handleClose} variant="text">
                                  Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
