import React, { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

interface FolderBackButtonProps {
    name: string;
    parentPath: string;
    isPublic: boolean;
}

export const FolderBackButton: React.FC<FolderBackButtonProps> = ({ name, parentPath, isPublic }) => {
    const navigate = useNavigate();
    const selectFolder = useCallback(() => {
        const visibility = isPublic ? "public" : "private";
        if (parentPath === "root") {
            navigate(`${visibility}`);
        } else if (parentPath === "") {
            navigate(`${visibility}/${name}`);
        } else {
            navigate(`${visibility}/${parentPath}/${name}`);
        }
    }, [navigate, name, parentPath, isPublic]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
                variant="outlined"
                size="small"
                onClick={selectFolder}
                startIcon={<KeyboardArrowLeftIcon />}
                sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
            >
                Back
            </Button>
        </Box>
    );
};
