import { InferType, mixed, object, string, array } from "yup";

export const AssetUploadSchema = () => {
    return object({
        fileNames: array().of(string()
            .min(1)
            .required()
            .test("slash", "You are unable to add a title with a / in it.", (val) => {
                if (val === undefined) {
                    return true;
                }

                return val.indexOf("/") === -1 && val.indexOf("\\") === -1;
            }))
            .min(1).required("No files selected"),
        files: array().of(mixed())
            .required(),
        extensions: array().of(string().min(1).required("Extension not specified")).min(1).required("No files selected"),
    });
};

const AssetUploadSchemaInstance = AssetUploadSchema();

export type AssetUploadType = InferType<typeof AssetUploadSchemaInstance>;
