import { Event } from "../../schemas/eventManagement/eventSchema";

const getEvents = <schema>(get: <T>(url: string) => Promise<T>) => async () => {
    const result = await get<schema>(`/api/event`);
    return result;
};

const getEvent = <schema>(get: <T>(url: string) => Promise<T>) => async (eventId: string) => {
    const result = await get<schema>(`/api/event/${eventId}`);
    return result;
};

const createEvent = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (form: Event) => {
    const result = await post<schema>(`/api/event`, form);
    return result;
};

const updateEvent = <schema>(put: <T>(url: string, body: any) => Promise<T>) => async (eventId: string, form: Event) => {
    const result = await put<schema>(`/api/event/${eventId}`, form);
    return result;
};

const deleteEvent = <schema>(deleteRequest: <T>(url: string, body: any) => Promise<T>) => async (eventId: string) => {
    const result = await deleteRequest<schema>(`/api/event/${eventId}`, {});
    return result;
};

const getEventCategories = <schema>(get: <T>(url: string) => Promise<T>) => async () => {
    const result = await get<schema>(`/api/event/categories`);
    return result;
};

export { getEvents, getEvent, createEvent, updateEvent, deleteEvent, getEventCategories };
