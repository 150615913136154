import * as yup from "yup";

export type Question = {
    rowKey?: string;
    eventId?: string;
    title?: string;
    text?: string;
    options?: string;
    description?: string;
    questionType?: QuestionType;
    required?: boolean;
    timestamp?: Date;
    deleted?: boolean;
};

export enum QuestionType {
    FreeText = 0,
    SingleSelect = 1,
    DatePicker = 2,
    Checkbox = 3,
};

export const QuestionSchema: yup.SchemaOf<Question> = yup.object({
    rowKey: yup.string().notRequired(),
    eventId: yup.string().notRequired(),
    title: yup.string().required(),
    text: yup.string().required(),
    questionType: yup.mixed<QuestionType>().oneOf(Object.values(QuestionType) as number[]).required(),
    required: yup.boolean().notRequired(),
    options: yup.string().notRequired(),
    description: yup.string().notRequired(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired()
});

export const QuestionTypeText = {
    [QuestionType.FreeText]: "Free Text",
    [QuestionType.SingleSelect]: "Single Select",
    [QuestionType.DatePicker]: "Date Picker",
    [QuestionType.Checkbox]: "Checkbox",
};
