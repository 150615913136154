import {  Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface FolderCardProps {
    name: string;
    parentPath: string;
    isPublic: boolean;
    friendlyName: string;
}

export const FolderCard = ({ name, parentPath, isPublic, friendlyName }: FolderCardProps) =>
{
    const navigate = useNavigate();
    const selectFolder = useCallback(() => {
        const visibility = isPublic ? "public" : "private";
        if (parentPath === "root") {
            navigate(`${visibility}`);
        }
        else if (parentPath === "") {
            navigate(`${visibility}/${name}`);
        }
        else {
            navigate(`${visibility}/${parentPath}/${name}`);
        }
    }, [navigate, name, parentPath, isPublic]);

    const truncatedFriendlyName = friendlyName.length > 14 ? `${friendlyName.substring(0, 14)}...` : friendlyName;

    return (<Card sx={{ backgroundColor: '#25454d', color: 'white' }}>
        <CardActionArea onClick={selectFolder}>
            <CardContent>
                <Typography gutterBottom variant="h5" fontWeight="bold" component="div" sx={{ textAlign: 'center' }}>{truncatedFriendlyName}</Typography>
            </CardContent>
        </CardActionArea>
    </Card>);
};
