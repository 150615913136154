import {  Card, CardActionArea, Box, Divider, CardContent, Typography, Avatar } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface MainFolderCardProps {
    name: string;
    parentPath: string;
    isPublic: boolean;
    friendlyName: string;
    description: string;
    icon?: React.ReactNode;
}

export const MainFolderCard = ({ name, parentPath, isPublic, friendlyName, description ,icon }: MainFolderCardProps) =>
{
    const navigate = useNavigate();
    const selectFolder = useCallback(() => {
        const visibility = isPublic ? "public" : "private";
        if (parentPath === "root") {
            navigate(`${visibility}`);
        }
        else if (parentPath === "") {
            navigate(`${visibility}/${name}`);
        }
        else {
            navigate(`${visibility}/${parentPath}/${name}`);
        }
    }, [navigate, name, parentPath, isPublic]);

    return (<Card className="bb-app-icon">
        <CardActionArea onClick={selectFolder} sx={{ flexGrow: 1 }}>
            <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                <Avatar
                    sx={{
                        backgroundColor: 'transparent!important',
                        padding: '2rem'
                    }}>
                    {icon}
                </Avatar>
            </Box>
            <CardContent>
                <Typography gutterBottom variant="h2" fontWeight="bold" component="div" sx={{ textAlign: 'center' }}>{friendlyName}</Typography>
                <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                    {description}
                </Typography>
            </CardContent>
            <Divider />
        </CardActionArea>
    </Card>);
};
