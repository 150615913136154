import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Tooltip,
    Typography,
    Checkbox
} from "@mui/material";

import "./event.css";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import FormControlLabel from "@mui/material/FormControlLabel";

import { Field, FieldProps, Form, Formik } from "formik";

import { useCallback, useContext, useEffect, useState } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { Link, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import useDataClient from "../../../axios/dataClient";

import { TextField } from "../../../material/TextField";

import { Select } from "../../../material/Select";

import {
    Event,
    EventBookerType,
    EventSchema,
    EventType,
    Status
} from "../../../schemas/eventManagement/eventSchema";

import { ResultEntityModel, ResultListModel, ResultModel } from "../../../schemas/eventManagement/resultSchema";

import { createEvent, deleteEvent, getEvent, updateEvent } from "../../../services/api/EventManagementService";

import { MemberAppContext } from "../../../MemberAppContext";

import { UndoButton } from "../../applications/UndoButton";

import { stringSort } from "../../../services/helper/sort";

import { WarningModal } from "../../shared/WarningModal";

import { newEvent } from "../../newModuleNames";

import { errors } from "../../ErrorDisplay";

import { Partner } from "../../../schemas/partnerManagement/schema";

import { getPartners } from "../../../services/api/PartnerManagementService";
import { getEventCategories } from "../../../services/api/EventManagementService";
import { AltMediaLibraryWidget } from "../../applications/customWidget/MediaLibrary/AltMediaLibraryWidget";

import { EventDescriptionModal } from "./EventDescriptionModal";

import { EventPreviewModal } from "./EventPreviewModal";

export const EventForm = () => {
    const { put, post, get, deleteRequest } = useDataClient();
    const { eventId } = useParams();
    const navigate = useNavigate();
    const isDelete = location?.pathname?.endsWith("/delete");
    const appContext = useContext(MemberAppContext);

    const isPartner = appContext.user.isPartnerUser ? true : false;
    const [event, setEvent] = useState<Event>();
    const [isLoading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [eventCategories, setEventCategories] = useState<string[]>([]);
    const [statusLabel, setStatusLabel] = useState('Draft');

    const [previewOpen, setPreviewOpen] = useState(false);

    const handlePreviewOpen = () => setPreviewOpen(true);
    const handlePreviewClose = () => setPreviewOpen(false);

    const fetchData = useCallback(async () => {
        if (eventId) {
            getEvent<ResultEntityModel<Event>>(get)(eventId).then((eventData) => {
                if (eventData?.isSuccess) {
                    setEvent(eventData.entity);
                    if (eventData.entity) {
                        setStatusLabel(eventData.entity.status === Status.Published ? 'Live' : 'Draft');
                    }
                } else if (eventData?.messages) {
                    errors(eventData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setEvent({
                eventName: "",
                eventBookerType: EventBookerType.Customer,
                eventCategory: "",
                partnerId: "",
                eventDescription: "",
                eventLogo: "",
                eventType: EventType.Virtual,
                bookable: true,
                organiserName: "",
                organiserContact: "",
                organiserWebsite: "",
                buttonText: "Book Now",
                externalLink: "",
                status: Status.Unpublished
            });
            setLoading(false);
        }
    }, [get, eventId]);

    const fetchPartnerData = useCallback(async () => {

        getPartners<ResultListModel<Partner>>(get)().then((partnersData) => {
            if (partnersData?.isSuccess && partnersData?.data) {
                const partnersWithEmptyValue = [...partnersData.data.sort((a, b) => stringSort(a.partnerName, b.partnerName))];
                if (!appContext.user.isPartnerUser) {
                    partnersWithEmptyValue.splice(0, 0, { rowKey: "none", partnerName: "None" });
                }

                setPartners(partnersWithEmptyValue);
            }
            return;
        });
    }, [get, appContext.user.isPartnerUser]);

    const fetchEventCategories = useCallback(async () => {
        try {
            const categoriesData = await getEventCategories<string[]>(get)();
            if (categoriesData) {
                setEventCategories(categoriesData);
            } else {
                errors(["Failed to fetch event categories."]);
            }
        } catch (error) {
            if (error instanceof Error) {
                errors([error.message]);
            } else {
                errors(["An unknown error occurred."]);
            }
        }
    }, [get]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
            await fetchPartnerData();
            await fetchEventCategories();
        })();
    }, [fetchData, fetchPartnerData, fetchEventCategories]);

    const callSave = useCallback(async (eventData: Event) => {
        setSubmitting(true);
        const eventDataToSave = { ...eventData };
        eventDataToSave.partnerId = eventDataToSave.partnerId === "none" ? undefined : eventDataToSave.partnerId;

        if (eventId) {
            updateEvent<ResultModel>(put)(eventId, eventDataToSave).then((result) => {
                if (result.isSuccess) {
                    toast.success("Your Event Has Been Updated!");
                    setStatusLabel(eventDataToSave.status === Status.Published ? 'Live' : 'Draft');
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else {
            eventDataToSave.eventBookerType = appContext.user.isPartnerUser ? EventBookerType.Partner : EventBookerType.Customer;

            createEvent<ResultEntityModel<Event>>(post)(eventDataToSave).then((result) => {
                if (result.isSuccess) {
                    toast.success("Your Event Has Been Created!");
                    setStatusLabel(eventDataToSave.status === Status.Published ? 'Live' : 'Draft');
                    navigate(`/eventManagement/events/${result.entity?.rowKey}`);
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [put, post, eventId, navigate, appContext.user]);

    const navigateToDelete = () => {
        navigate(`/eventManagement/events/${eventId}/delete`);
    };

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);

        fetchData().then(() => {
            toast.warning("Event data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (eventId) {
            setSubmitting(true);
            if (event) {
                event.deleted = true;
                return deleteEvent<ResultModel>(deleteRequest)(eventId).then((result) => {
                    if (result?.isSuccess) {
                        toast.warning(`Event has been marked as deleted`);
                        setLoading(true);
                        fetchData();
                        return true;
                    } else if (result?.messages) {
                        errors(result.messages);
                    }
                    return false;
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }

        return false;
    }, [eventId, deleteRequest, event, fetchData]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        await handleDelete();
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}`);
    }, [navigate, handleDelete, eventId]);

    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}`);
    };

    const handleWarningModalOpen = () => setWarningModalOpen(true);

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    const navigateToSchedules = () => {
        navigate(`/eventManagement/events/${eventId}/schedules`);
    };

    const navigateToQuestions = () => {
        navigate(`/eventManagement/events/${eventId}/questions`);
    };

    useEffect(() => {
        if (event?.status === Status.Published) {
            setStatusLabel('Live');
        } else {
            setStatusLabel('Draft');
        }
    }, [event?.status]);

    if (isLoading || !event) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Container>
                <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="bb-title-bar">
                        <h2 className="bb-m0 bb-p0">Events</h2>
                    </Box>
                    <Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events`}>
                            <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                                startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Events
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/eventManagement">Event Management</Link>
                    <Link to="/eventManagement/events">Events</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">{event?.eventName ? event?.eventName : newEvent}</Typography>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '20px!important' }}
                >
                Welcome to the <b>Events Page.</b> Here, you can configure your
                    Event Information & add Event Schedules.
                Once you have created your event, please navigate to Schedules to add the dates your events will take place.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="bb-flex page-edit-buttons bb-mobile-flex" sx={{ marginBottom: '1rem' }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 0 }} alignContent="left"
                                        className="bb-mb-0 bb-pb-0">
                                    Event Editor
                                    </Typography>
                                </Box>
                                <Box className="form-buttons" sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    {eventId &&
                                <>
                                    <Tooltip
                                        title="Schedules enable you to add the dates your events will take place."
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: 'black',
                                                    color: 'white',
                                                    fontSize: '.8rem',
                                                    padding: '0.5rem',
                                                },
                                                '& .MuiTooltip-arrow': {
                                                    color: 'black',
                                                },
                                            },
                                        }}>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToSchedules}
                                                startIcon={<CalendarMonthOutlinedIcon />}
                                                variant="outlined"
                                                disabled={submitting}>
                                            Schedules
                                            </Button>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip
                                        title="Add Custom Questions to your Events that you require your users to answer."
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: 'black',
                                                    color: 'white',
                                                    fontSize: '.8rem',
                                                    padding: '0.5rem',
                                                },
                                                '& .MuiTooltip-arrow': {
                                                    color: 'black',
                                                },
                                            },
                                        }}>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToQuestions}
                                                startIcon={<QuestionMarkOutlinedIcon />}
                                                variant="outlined"
                                                disabled={submitting}>
                                            Questions
                                            </Button>
                                        </Grid>
                                    </Tooltip>
                                    {!event.deleted &&
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDelete}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                disabled={submitting}>
                                                Delete
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs={1}>
                                        <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                    </Grid>
                                </>
                                    }
                                    <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="event_submit">
                                    Save
                                    </Button>
                                </Box>
                            </Box>
                            <Box className="status-container" sx={{ marginTop: '20px' }}>
                                <Box sx={{ marginRight: '10px', marginBottom: '20px' }}>
                                    {eventId && (
                                        <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
                                            {statusLabel === 'Live' ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: 'green',
                                                        backgroundColor: 'rgba(0, 128, 0, 0.1)',
                                                        padding: '10px 60px',
                                                        borderRadius: '10px' }}>
                                                    <CheckCircleOutlineIcon sx={{ marginRight: '8px' }} />
                                         Published
                                                </Box>
                                            ) : (
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'red',
                                                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                    padding: '10px 60px',
                                                    borderRadius: '10px' }}>
                                                    <ErrorOutlineIcon sx={{ marginRight: '8px' }} />
                                         Unpublished
                                                </Box>
                                            )}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginRight: '10px', marginBottom: '20px' }}>
                                    {eventId && (
                                        <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
                                            {event?.bookable ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        color: 'blue',
                                                        backgroundColor: 'rgba(0, 0, 255, 0.1)',
                                                        padding: '10px 60px',
                                                        borderRadius: '10px' }}>
                                                    <CheckCircleOutlineIcon sx={{ marginRight: '8px' }} />
                                                       Bookable
                                                </Box>
                                            ) : (
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'orange',
                                                    backgroundColor: 'rgba(255, 165, 0, 0.1)',
                                                    padding: '10px 60px',
                                                    borderRadius: '10px' }}>
                                                    <ErrorOutlineIcon sx={{ marginRight: '8px' }} />
                                                    None Bookable
                                                </Box>
                                            )}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Formik<Event>
                                        initialValues={event}
                                        onSubmit={callSave}
                                        enableReinitialize={true}
                                        validationSchema={EventSchema}>
                                        <Form
                                            id={"event_submit"}
                                            className="bb-pathways-initial-form"
                                        >
                                            <Typography variant="h3" sx={{ fontWeight: 'bold', marginTop: '5px', marginBottom: '14px' }}>
                                                Event Details
                                            </Typography>
                                            <TextField
                                                name="eventName"
                                                label="Event Name"
                                                variant="outlined"
                                                sx={{ marginBottom: '30px', marginTop: '20px' }}
                                                required
                                            />
                                            <Field name="eventLogo" sx={{ marginTop: '30px', marginBottom: '30px' }}>
                                                {({ field, form }: FieldProps) => (
                                                    <AltMediaLibraryWidget
                                                        onChange={(newValue: string) => form.setFieldValue(field.name, newValue)}
                                                        id="eventLogo"
                                                        value={field.value}
                                                        propLabel="Event Image"
                                                        schema={{ height: 200, width: 300 }}
                                                    />
                                                )}
                                            </Field>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                marginBottom: '10px' }}>
                                                <Field name="eventDescription" label="Event Description">
                                                    {({ field, form }: FieldProps) => (
                                                        <EventDescriptionModal
                                                            value={field.value}
                                                            onChange={(newValue: string) => form.setFieldValue(field.name, newValue)}
                                                        />
                                                    )}
                                                </Field>
                                            </Box>
                                            <TextField
                                                name="buttonText"
                                                label="Button Text"
                                                variant="outlined"
                                                required
                                                sx={{ marginTop: '30px' }}
                                            />
                                            <Tooltip
                                                title="Please select the category for your event."
                                                arrow
                                                placement="top"
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            fontSize: '.8rem',
                                                            padding: '0.5rem',
                                                        },
                                                        '& .MuiTooltip-arrow': {
                                                            color: 'black',
                                                        },
                                                    },
                                                }}>
                                                <Box sx={{ marginTop: '30px' }}>
                                                    <Select
                                                        name="eventCategory"
                                                        label="Event Category"
                                                        variant="outlined"
                                                        required
                                                        sx={{ width: '100%' }}
                                                    >
                                                        {eventCategories.map((category) => (
                                                            <MenuItem key={category} value={category}>
                                                                <ListItemText primary={category} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            </Tooltip>
                                            <Box sx={{ marginTop: '20px' }}>
                                                <InputLabel id="status-label" sx={{ textAlign: 'left' }}>Status</InputLabel>
                                                <Tooltip
                                                    title="Published events are live and can be viewed by your Users.
                                                Unpublished events are in draft mode and are not visible to your Users.
                                                Please do not publish your Event until you have created your schedules."
                                                    arrow
                                                    placement="top"
                                                    PopperProps={{
                                                        sx: {
                                                            '& .MuiTooltip-tooltip': {
                                                                backgroundColor: 'black',
                                                                color: 'white',
                                                                fontSize: '.8rem',
                                                                padding: '0.5rem',
                                                            },
                                                            '& .MuiTooltip-arrow': {
                                                                color: 'black',
                                                            },
                                                        },
                                                    }}>
                                                    <Box>
                                                        <Field
                                                            as={Select}
                                                            name="status"
                                                            labelId="status-label"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            sx={{ marginBottom: '20px' }}
                                                        >
                                                            <MenuItem value={Status.Published}>Published</MenuItem>
                                                            <MenuItem value={Status.Unpublished}>Unpublished</MenuItem>
                                                        </Field>
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                            <TextField
                                                name="maxBookingsPerPerson"
                                                label="Max Bookings Per-Person"
                                                variant="outlined"
                                                required
                                                type="number"
                                                inputProps={{ step: 1,  min: 0 }}
                                                sx={{ marginTop: '20px', marginBottom: '20px' }}
                                            />
                                            <Field name="bookable">
                                                {({ field, form }: FieldProps) => (
                                                    <>
                                                        <Tooltip
                                                            title="If you are using the Beebot AI booking functionality,
                                                            please DO check this box. If you do not require users to book,
                                                            or you are using a 3rd party booking provider, please DO NOT check this box."
                                                            arrow
                                                            placement="top"
                                                            PopperProps={{
                                                                sx: {
                                                                    '& .MuiTooltip-tooltip': {
                                                                        backgroundColor: 'black',
                                                                        color: 'white',
                                                                        fontSize: '.8rem',
                                                                        padding: '0.5rem',
                                                                    },
                                                                    '& .MuiTooltip-arrow': {
                                                                        color: 'black',
                                                                    },
                                                                },
                                                            }}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                border: '1px solid #e0e0e0',
                                                                padding: '16px',
                                                                borderRadius: '8px',
                                                                marginBottom: '25px',
                                                                marginTop: '15px'
                                                            }}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...field}
                                                                            checked={field.value}
                                                                            onChange={() => form.setFieldValue(field.name, !field.value)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Is this a Bookable Event?"
                                                                    sx={{ float: 'left' }}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                        {!field.value && (
                                                            <TextField
                                                                name="externalLink"
                                                                label="External URL"
                                                                variant="outlined"
                                                                sx={{ marginBottom: '30px' }}
                                                                required
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </Field>
                                            <Typography variant="h3" sx={{ fontWeight: 'bold', marginTop: '30px', marginBottom: '5px' }}>
                                                Organiser Details
                                            </Typography>
                                            <Tooltip
                                                title="If you are creating an event for a partner, please select the partner here."
                                                arrow
                                                placement="top"
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            fontSize: '.8rem',
                                                            padding: '0.5rem',
                                                        },
                                                        '& .MuiTooltip-arrow': {
                                                            color: 'black',
                                                        },
                                                    },
                                                }}>
                                                <Box sx={{ marginTop: '20px' }}>
                                                    { !isPartner &&
                                            <Select
                                                name="partnerId"
                                                label="Partner"
                                                variant="outlined"
                                                disabled={isPartner}
                                                required={isPartner}
                                            >
                                                {partners?.map((partner) => (
                                                    <MenuItem key={partner.rowKey} value={partner.rowKey}>
                                                        <ListItemText primary={partner.partnerName} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                                    }
                                                </Box>
                                            </Tooltip>
                                            <Tooltip
                                                title="Please provide contact details for the organiser of this
                                                event in case a person attending your event needs to contact you."
                                                arrow
                                                placement="top"
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            fontSize: '.8rem',
                                                            padding: '0.5rem',
                                                        },
                                                        '& .MuiTooltip-arrow': {
                                                            color: 'black',
                                                        },
                                                    },
                                                }}>
                                                <TextField
                                                    name="organiserName"
                                                    label="Organiser Name"
                                                    variant="outlined"
                                                    sx={{ marginTop: '30px' }}
                                                    required
                                                />
                                            </Tooltip>
                                            <TextField
                                                name="organiserWebsite"
                                                label="Organiser Website"
                                                variant="outlined"
                                                required
                                                sx={{ marginTop: '30px' }}
                                            />
                                            <TextField
                                                name="organiserContact"
                                                label="Organiser Email"
                                                variant="outlined"
                                                required
                                                sx={{ marginTop: '30px', marginBottom: '22px' }}
                                            />
                                            {eventId &&
                                                <Box sx={{ textAlign: "left" }}>
                                                    <label>Deleted: {event.deleted ? "Yes" : "No"}</label>
                                                </Box>
                                            }
                                        </Form>
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <IconButton
                    onClick={handlePreviewOpen}
                    sx={{
                        position: 'fixed',
                        bottom: 28,
                        right: 22,
                        backgroundColor: '#11a681',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#0e8e6f'
                        },
                        borderRadius: '50%',
                        width: 64,
                        height: 64,
                        boxShadow: 3
                    }}
                >
                    <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                </IconButton>
                <EventPreviewModal event={event} open={previewOpen} onClose={handlePreviewClose} />
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleWarningModalClose}
            />
        </>);
};
