import { ImageNotSupported } from "@mui/icons-material";
import {
    Card,
    CardActionArea,
    CardMedia,
    Checkbox,
    Grid,
    Box,
    Icon,
    Typography
} from "@mui/material";

import { useCallback, useMemo, useState } from "react";

import useDataClient from "../../axios/dataClient";

import { error } from "../ErrorDisplay";

import { UploadAssetDialog } from "./uploadAsset";

import { FileInfoDialog } from "./fileInfo";

import { BlobFile } from "./folder";

import { DeleteAssetDialog } from ".";

export interface FileProps {
    file: BlobFile;
    isPublic: boolean;
    isMultipleDeleting: boolean;
    isBeingDeleted: boolean;
    toggleForDeletion: (file: BlobFile, isCurrentlySelected: boolean) => void;
    onSuccessfulEdit: (newFile: BlobFile, oldFile: BlobFile) => void;
    onSuccessfulDelete: (name: string) => void;
}

export const File = ({
    file,
    isPublic,
    isMultipleDeleting,
    isBeingDeleted,
    toggleForDeletion,
    onSuccessfulEdit,
    onSuccessfulDelete }: FileProps) => {
    const [editOpen, setEditOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { deleteRequest } = useDataClient();

    const [fileName, extension] = useMemo(() => {
        const parts = file.name.split(".");
        if (parts.length < 2) {
            return [parts[0], ""];
        }
        return [parts.slice(0, -1).join("."), parts[parts.length - 1]];
    }, [file]);

    const deleteFile = useCallback(async () => {
        const params = new URLSearchParams();
        params.append("fileName", file.name);
        params.append("filePath", file.path);
        params.append("isPublic", isPublic.toString());
        await deleteRequest("api/asset?" + params.toString());
    }, [deleteRequest, file, isPublic]);

    const closeEdit = useCallback(() => {
        setEditOpen(false);
    }, []);

    const edit = useCallback(async (newFiles: BlobFile[]) => {
        if (newFiles.length !== 1) {
            error("Expected edit to affect a single file");
        }
        const newFile = newFiles[0];

        if (newFile.name !== file.name) {
            await deleteFile();
        }
        onSuccessfulEdit(newFile, file);
        setEditOpen(false);
    }, [onSuccessfulEdit, file, deleteFile]);

    const openInfo = useCallback(() => {
        setInfoOpen(true);
    }, []);

    const closeInfo = useCallback(() => {
        setInfoOpen(false);
    }, []);

    const closeDelete = useCallback(() => {
        setDeleteOpen(false);
    }, []);

    const onDelete = useCallback(async () => {
        await deleteFile();
        setDeleteOpen(false);
        onSuccessfulDelete(file.name);
    }, [file, onSuccessfulDelete, deleteFile]);

    const shouldShowThumbnail = useMemo(() => {
        const upper = file.name.toLocaleUpperCase();
        return isPublic && (upper.endsWith("PNG") || upper.endsWith("JPEG") || upper.endsWith("JPG") || upper.endsWith("GIF"));
    }, [file, isPublic]);

    const selectForDeletion = useCallback(() => {
        toggleForDeletion(file, isBeingDeleted);
    }, [toggleForDeletion, file, isBeingDeleted]);

    const renderLastUpdated = useCallback((lastUpdated?: Date | string) => {
        if (typeof lastUpdated === "string") {
            return new Date(lastUpdated).toLocaleString();
        }
        else {
            return lastUpdated?.toLocaleString();
        }
    }, []);

    return (
        <Grid item xs={6} sm={4} md={3} lg={2} xl={1.5} className="bb-media-card-cont">
            <Card className="bb-media-card" sx={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)!important' }}>
                <Box onClick={openInfo}>
                    {shouldShowThumbnail ? <CardMedia component="img" image={file.url} /> : <CardMedia><Icon><ImageNotSupported /></Icon></CardMedia>}
                </Box>
                <CardActionArea className="bb-media-item-info bb-flex bb-flex-column">
                    <Typography sx={{ fontSize: "0.9", fontWeight: "bold", padding: "0.5rem 0.5rem 0 0.5rem", minHeight: "4rem" }}>
                        {isMultipleDeleting ? <Checkbox sx={{ padding: "0" }} checked={isBeingDeleted} onClick={selectForDeletion} /> : null} {file.name}
                    </Typography>
                    <Typography sx={{ fontSize: "0.7rem", padding: "0 0.5rem", marginTop: "auto" }}>{renderLastUpdated(file.lastUpdated)}</Typography>
                </CardActionArea>
            </Card>
            <UploadAssetDialog
                open={editOpen}
                onCancel={closeEdit}
                onConfirm={edit}
                filePath={file.path}
                isPublic={isPublic}
                isSingleFile={true}
                existingData={{ fileNames: [fileName], extensions: [extension], files: [] }}
                existingAssets={[]} />
            <DeleteAssetDialog
                open={deleteOpen}
                onCancel={closeDelete}
                onDelete={onDelete}
            />
            <FileInfoDialog
                open={infoOpen}
                onCancel={closeInfo}
                file={file} />
        </Grid>);
};
