import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Breadcrumbs,
    Typography,
    Alert,
    MenuItem,
    Select, InputLabel,
    Checkbox
} from "@mui/material";

import { Field, Form, Formik } from "formik";

import { useState, useCallback, useEffect } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import FormControlLabel from "@mui/material/FormControlLabel";

import { useParams, Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import useDataClient from "../../../axios/dataClient";

import { TextField } from "../../../material/TextField";

import {
    Question,
    QuestionSchema,
    QuestionType,
    QuestionTypeText
} from "../../../schemas/eventManagement/questionSchema";

import { ResultModel, ResultEntityModel } from "../../../schemas/eventManagement/resultSchema";

import { getQuestion, createQuestion, updateQuestion, deleteQuestion } from "../../../services/api/QuestionManagementService";

import { UndoButton } from "../../applications/UndoButton";

import { WarningModal } from "../../shared/WarningModal";

import { errors } from "../../ErrorDisplay";

export const QuestionForm = () => {

    const { put, post, get, deleteRequest } = useDataClient();
    const { eventId, questionId } = useParams();
    const navigate = useNavigate();
    const isDelete = location?.pathname?.endsWith("/delete");

    const [question, setQuestion] = useState<Question>();
    const [isLoading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);

    const fetchData = useCallback(async () => {
        if (!eventId) {
            return;
        }

        if (questionId) {
            getQuestion<ResultEntityModel<Question>>(get)(eventId, questionId).then((questionData) => {
                if (questionData?.isSuccess) {
                    setQuestion(questionData.entity);
                } else if (questionData?.messages) {
                    errors(questionData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setQuestion({
                text: "",
                questionType: QuestionType.FreeText,
                options: "",
                required: false,
                description: ""
            });
            setLoading(false);
        }
    }, [get, questionId, eventId]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    const callSave = useCallback(async (questionData: Question) => {
        if (!eventId) {
            return;
        }

        setSubmitting(true);

        if (!question) {
            setSubmitting(false);
            return;
        }

        const questionDataToSave: Question = { ...questionData, eventId };

        if (questionId) {
            updateQuestion<ResultModel>(put)(eventId, questionId, questionDataToSave).then((result) => {
                if (result.isSuccess) {
                    toast.success("Question updated");
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else {
            createQuestion<ResultEntityModel<Question>>(post)(eventId, questionDataToSave).then((result) => {
                if (result.isSuccess) {
                    toast.success("Question created");
                    navigate(`/eventManagement/events/${eventId}/questions/${result.entity?.rowKey}`);
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [put, post, eventId, questionId, navigate, question]);

    const navigateToDelete = () => {
        navigate(`/eventManagement/events/${eventId}/questions/${questionId}/delete`);
    };

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);

        fetchData().then(() => {
            toast.warning("Question data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (questionId && eventId) {
            setSubmitting(true);
            if (question) {
                return deleteQuestion<ResultModel>(deleteRequest)(eventId, questionId).then((result) => {
                    if (result?.isSuccess) {
                        setQuestion({ ...question, deleted: true });
                        toast.warning(`Question has been marked as deleted`);
                        setLoading(true);
                        fetchData();
                        return true;
                    } else if (result?.messages) {
                        errors(result.messages);
                    }
                    return false;
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }

        return false;
    }, [questionId, eventId, deleteRequest, question, fetchData]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        await handleDelete();
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}/questions/${questionId}`);
    }, [navigate, handleDelete, eventId, questionId]);

    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}/questions/${questionId}`);
    };

    const handleWarningModalOpen = () => setWarningModalOpen(true);

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    const navigateToQuestionAnswers = () => {
        navigate(`/eventManagement/events/${eventId}/questions/${questionId}/questionAnswers`, { state: { questionText: question?.text } });
    };

    if (isLoading || !question) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Container>
                <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="bb-title-bar">
                        <h2 className="bb-m0 bb-p0">Questions</h2>
                    </Box>
                    <Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/questions`}>
                            <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                                startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Questions
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/eventManagement">Event Management</Link>
                    <Link to="/eventManagement/events">Events</Link>
                    <Link to={`/eventManagement/events/${eventId}/questions`}>Questions</Link>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '20px!important' }}
                >
                Welcome to the <b>Question Editor Page</b>. This page enables you to manage and configure your question.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="page-edit-buttons bb-mb-4" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 0 }} alignContent="left"
                                        className="bb-mb-0 bb-pb-0">
                                    Question Editor
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {questionId &&
                                <>
                                    <Grid item xs={1}>
                                        <Button
                                            onClick={navigateToQuestionAnswers}
                                            variant="outlined"
                                            startIcon={<QuestionAnswerOutlinedIcon />}
                                            disabled={submitting}>
                                            Answers
                                        </Button>
                                    </Grid>
                                    {!question.deleted &&
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDelete}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                disabled={submitting}>
                                                Delete
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs={1}>
                                        <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                    </Grid>
                                </>
                                    }
                                    <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="question_submit">
                                    Save
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Formik<Question>
                                        initialValues={question}
                                        onSubmit={callSave}
                                        enableReinitialize={true}
                                        validationSchema={QuestionSchema}>
                                        {({ values }) => (
                                            <Form
                                                id={"question_submit"}
                                                className="bb-pathways-initial-form"
                                            >
                                                <TextField
                                                    name="title"
                                                    label="Question Title"
                                                    sx={{ marginBottom: '30px' }}
                                                    variant="outlined"
                                                    required
                                                />
                                                <TextField
                                                    name="text"
                                                    label="Question Text"
                                                    multiline
                                                    sx={{ marginBottom: '30px' }}
                                                    variant="outlined"
                                                    required
                                                />
                                                <InputLabel id="questionType-label" sx={{ textAlign: 'left' }}>Question Type</InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="questionType"
                                                    labelId="questionType-label"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ marginBottom: '30px' }}
                                                >
                                                    <MenuItem value={QuestionType.FreeText}>{QuestionTypeText[QuestionType.FreeText]}</MenuItem>
                                                    <MenuItem value={QuestionType.SingleSelect}>{QuestionTypeText[QuestionType.SingleSelect]}</MenuItem>
                                                    <MenuItem value={QuestionType.DatePicker}>{QuestionTypeText[QuestionType.DatePicker]}</MenuItem>
                                                    <MenuItem value={QuestionType.Checkbox}>{QuestionTypeText[QuestionType.Checkbox]}</MenuItem>
                                                </Field>
                                                {values.questionType === QuestionType.SingleSelect && (
                                                    <TextField
                                                        name="options"
                                                        label="Question Options"
                                                        variant="outlined"
                                                        sx={{ marginBottom: '30px' }}
                                                    />
                                                )}
                                                <TextField
                                                    name="description"
                                                    label="Description"
                                                    variant="outlined"
                                                    sx={{ marginBottom: '30px' }}
                                                />
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    border: '1px solid #e0e0e0',
                                                    padding: '16px',
                                                    borderRadius: '8px',
                                                    marginBottom: '30px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Field
                                                                as={Checkbox}
                                                                name="required"
                                                                color="primary"
                                                                type="checkbox"
                                                            />
                                                        }
                                                        label="Is this question Mandatory?"
                                                        sx={{ float: 'left' }}
                                                    />
                                                </Box>
                                                <Box>
                                                    {questionId &&
                                                <Box sx={{ textAlign: "left" }}>
                                                    <label>Deleted: {question.deleted ? "Yes" : "No"}</label>
                                                </Box>
                                                    }
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleWarningModalClose}
            />
        </>);
};
