import * as yup from "yup";

export type Venue = {
    rowKey?: string;
    venueName?: string;
    venueLocation?: string;
    onlineLocation?: string;
    timestamp?: Date;
    deleted?: boolean;
};

export const VenueSchema: yup.SchemaOf<Venue> = yup.object({
    rowKey: yup.string().notRequired(),
    venueName: yup.string().required(),
    venueLocation: yup.string().notRequired(),
    onlineLocation: yup.string().notRequired(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired()
});

