import * as yup from "yup";

export type Event = {
    rowKey?: string;
    eventName: string;
    eventBookerType: EventBookerType;
    eventCategory: string;
    partnerId?: string;
    eventDescription?: string;
    eventLogo?: string;
    otherImages?: string;
    eventType?: EventType;
    organiserName?: string;
    organiserContact?: string;
    organiserWebsite?: string;
    status?: Status;
    maxBookingsPerPerson?: number;
    bookable?: boolean;
    buttonText?: string;
    externalLink?: string;
    timestamp?: Date;
    deleted?: boolean;
};

export enum EventBookerType {
    Customer = 0,
    Partner = 1
};

export enum EventType {
    Virtual = 0,
    InPerson = 1
};

export enum Status {
    Published = 0,
    Unpublished = 1
};

export const EventBookerTypeText = {
    [EventBookerType.Customer]: "Customer",
    [EventBookerType.Partner]: "Partner"
};

export const EventTypeText = {
    [EventType.Virtual]: "Virtual",
    [EventType.InPerson]: "In Person"
};

export const StatusText = {
    [Status.Published]: "Published",
    [Status.Unpublished]: "Unpublished"
};

export const EventSchema: yup.SchemaOf<Event> = yup.object({
    rowKey: yup.string().notRequired(),
    maxBookingsPerPerson: yup.number().required(),
    eventName: yup.string().required(),
    eventBookerType: yup.mixed<EventBookerType>().oneOf(Object.values(EventBookerType) as number[]).required(),
    eventCategory: yup.string().required(),
    partnerId: yup.string().notRequired(),
    eventDescription: yup.string().notRequired(),
    eventLogo: yup.string().notRequired(),
    otherImages: yup.string().notRequired(),
    eventType: yup.mixed<EventType>().oneOf(Object.values(EventType) as number[]).notRequired(),
    bookable: yup.boolean().notRequired(),
    buttonText: yup.string().notRequired(),
    externalLink: yup.string().notRequired(),
    organiserName: yup.string().required(),
    organiserContact: yup.string().required(),
    organiserWebsite: yup.string().required(),
    status: yup.mixed<Status>().oneOf(Object.values(Status) as number[]).required(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired()
});
