import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Typography,
    Breadcrumbs,
    Alert,
    Tooltip
} from "@mui/material";

import { Form, Formik } from "formik";

import { useState, useCallback, useEffect } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { useParams, Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import useDataClient from "../../../axios/dataClient";

import { TextField } from "../../../material/TextField";

import { Venue, VenueSchema } from "../../../schemas/eventManagement/venueSchema";

import { ResultModel, ResultEntityModel } from "../../../schemas/eventManagement/resultSchema";

import { getVenue, createVenue, updateVenue, deleteVenue } from "../../../services/api/VenueManagementService";

import { UndoButton } from "../../applications/UndoButton";

import { WarningModal } from "../../shared/WarningModal";

import { newVenue } from "../../newModuleNames";

import { errors } from "../../ErrorDisplay";

export const VenueForm = () => {

    const { put, post, get, deleteRequest } = useDataClient();
    const { venueId } = useParams();
    const navigate = useNavigate();
    const isDelete = location?.pathname?.endsWith("/delete");

    const [venue, setVenue] = useState<Venue>();
    const [isLoading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);

    const fetchData = useCallback(async () => {
        if (venueId) {
            getVenue<ResultEntityModel<Venue>>(get)(venueId).then((venueData) => {
                if (venueData?.isSuccess) {
                    setVenue(venueData.entity);
                } else if (venueData?.messages) {
                    errors(venueData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setVenue({
                venueName: "",
                venueLocation: "",
                onlineLocation: "",
            });
            setLoading(false);
        }
    }, [get, venueId]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    const callSave = useCallback(async (venueData: Venue) => {
        setSubmitting(true);
        if (venueId) {
            updateVenue<ResultModel>(put)(venueId, venueData).then((result) => {
                if (result.isSuccess) {
                    toast.success("Venue updated");
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else {
            createVenue<ResultEntityModel<Venue>>(post)(venueData).then((result) => {
                if (result.isSuccess) {
                    toast.success("Venue created");
                    navigate(`/eventManagement/venues/${result.entity?.rowKey}`);
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [put, post, venueId, navigate]);

    const navigateToDelete = () => {
        navigate(`/eventManagement/venues/${venueId}/delete`);
    };

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);

        fetchData().then(() => {
            toast.warning("Venue data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (venueId) {
            setSubmitting(true);
            if (venue) {
                venue.deleted = true;
                return deleteVenue<ResultModel>(deleteRequest)(venueId).then((result) => {
                    if (result?.isSuccess) {
                        toast.warning(`Venue has been marked as deleted`);
                        setLoading(true);
                        fetchData();
                        return true;
                    } else if (result?.messages) {
                        errors(result.messages);
                    }
                    return false;
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }

        return false;
    }, [venueId, deleteRequest, venue, fetchData]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        await handleDelete();
        setWarningModalOpen(false);
        navigate(`/eventManagement/venues/${venueId}`);
    }, [navigate, handleDelete, venueId]);

    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/eventManagement/venues/${venueId}`);
    };

    const handleWarningModalOpen = () => setWarningModalOpen(true);

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    if (isLoading || !venue) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Container>
                <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="bb-title-bar">
                        <h2 className="bb-m0 bb-p0">Venues</h2>
                    </Box>
                    <Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/venues`}>
                            <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                                startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Venues
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/eventManagement">Event Management</Link>
                    <Link to="/eventManagement/venues">Venues</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">{venue?.venueName ? venue?.venueName : newVenue}</Typography>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '20px!important' }}
                >
                 Welcome to the <b>Venue Editor Page</b>. This page enables you to create and manage your venue.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="page-edit-buttons bb-mb-4" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 0 }} alignContent="left"
                                        className="bb-mb-0 bb-pb-0">
                                    Venue Editor
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {venueId &&
                                <>
                                    {!venue.deleted &&
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDelete}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                disabled={submitting}>
                                                Delete
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs={1}>
                                        <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                    </Grid>
                                </>
                                    }
                                    <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="venue_submit">
                                    Save
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Formik<Venue>
                                        initialValues={venue}
                                        onSubmit={callSave}
                                        enableReinitialize={true}
                                        validationSchema={VenueSchema}>
                                        <Form
                                            id={"venue_submit"}
                                            className="bb-pathways-initial-form"
                                        >
                                            <TextField
                                                name="venueName"
                                                label="Venue Name"
                                                variant="outlined"
                                                sx={{ marginBottom: '30px' }}
                                                required
                                            />
                                            <Tooltip
                                                title="If your event is In Person, please provide the address below."
                                                arrow
                                                placement="top"
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            fontSize: '.8rem',
                                                            padding: '0.5rem',
                                                        },
                                                        '& .MuiTooltip-arrow': {
                                                            color: 'black',
                                                        },
                                                    },
                                                }}>
                                                <TextField
                                                    name="venueLocation"
                                                    label="Venue Location"
                                                    variant="outlined"
                                                    sx={{ marginBottom: '30px' }}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title="If your event is online, please provide the the Microsft Teams/Zoom Link etc."
                                                arrow
                                                placement="top"
                                                PopperProps={{
                                                    sx: {
                                                        '& .MuiTooltip-tooltip': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            fontSize: '.8rem',
                                                            padding: '0.5rem',
                                                        },
                                                        '& .MuiTooltip-arrow': {
                                                            color: 'black',
                                                        },
                                                    },
                                                }}>
                                                <TextField
                                                    name="onlineLocation"
                                                    label="Webinar Link"
                                                    variant="outlined"
                                                    sx={{ marginBottom: '30px' }}
                                                />
                                            </Tooltip>
                                            {venueId &&
                                                <Box sx={{ textAlign: "left" }}>
                                                    <label>Deleted: {venue.deleted ? "Yes" : "No"}</label>
                                                </Box>
                                            }
                                        </Form>
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleWarningModalClose}
            />
        </>);
};
