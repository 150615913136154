import "./css/bb-pages.css";
import { useParams, Link } from "react-router-dom";
import {
    Container,
    Box,
    Alert,
    CircularProgress,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead
} from "@mui/material";

import { useState, useEffect, useCallback } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { getPages } from "../../../services/api/PagesService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { stringSort } from "../../../services/helper/sort";

import { SearchField } from "../customWidget/Search/SearchField";

import { PageFile } from "../../../schemas/pages/schema";

import { newPage as newPageName } from "../../newModuleNames";

import { FavoriteProvider } from '../../favorites/FavoriteContext';
import { FavoriteButton } from '../../favorites/FavoriteButton';

import { VideoHelper } from '../../shared/VideoHelper';

import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";
export const PageName = "Pages";

export const AppPages = () => {
    const { appId } = useParams();
    const { get } = useDataClient();

    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const [pageFiles, setPageFiles] = useState<PageFile[] | undefined>();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [searchString, setSearchString] = useState<string>();
    const [filteredPages, setFilteredPages] = useState<PageFile[] | undefined>();
    const videoUrl = 'https://media.bbotapp.com/pub/self-service-assist/Self-Service%20-%20Pages.m4v';

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    useEffect(() => {
        setSearchString("");
    }, []);

    const getFilteredPages = useCallback((filteredPageFiles: PageFile[]) => {
        return filteredPageFiles.map((pageFile) => {
            return { name: pageFile.name, content: "" };
        });
    }, []);

    useEffect(() => {
        if (!pageFiles) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        setCurrentPage(0);
        const filtered = pageFiles.filter((pageFile) =>
            !searchStringUpper || (pageFile?.name && pageFile?.name.toUpperCase().includes(searchStringUpper)));
        setFilteredPages(getFilteredPages(filtered));
    }, [searchString, pageFiles, getFilteredPages]);

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                getApp(get)(appId).then((data) => {
                    setAppData(data);
                    return;
                });

                getPages<PageFile[]>(get)(appId).then((pages) => {
                    setPageFiles(pages);
                    setFilteredPages(getFilteredPages(pages));
                    return;
                });
            }
        };

        fetchData();
    }, [appId, get, getFilteredPages]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    useEffect(() => {
        document.title = 'Beebot AI - Pages';
    },[]);

    if (!appData || !filteredPages) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Pages: {appData.displayName}</h2>
                    <FavoriteButton page={`applications/${appId}/pages`} displayName={`App: ${appData.displayName}`} displayModule={`Module: Pages`} />
                    <AppLinks />
                </Box>
                <Breadcrumb/>
                <Alert className="bb-title-info bb-mb-2" severity="info">
                Manage, create and edit pages within your <b>{appData.displayName}</b> application. To learn more about creating and managing your pages
                with Self-Service Assist&nbsp;
                    <VideoHelper
                        videoUrl={videoUrl}
                        triggerElement={
                            <a
                                href="#"
                                className="bb-mr-1"
                            >
                            Click Here
                            </a>
                        }
                    />
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-flex bb-tal bb-align-items-center bb-mb-2">
                        <h3 className="bb-m0 bb-p0">Your Pages</h3>
                        <Box className="bb-med-search">
                            <SearchField
                                searchString={searchString}
                                callSearch={callSearch}
                                timeout={500}
                            />
                        </Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/pages/${newPageName}`}>
                            <Button variant="contained">
                            New Page
                            </Button>
                        </Link>
                    </Box>
                    <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                        <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                            <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                                <TableRow >
                                    <TableCell>Page Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{
                                filteredPages
                                    .sort((a, b) => stringSort(a.name, b.name))
                                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                    .map((pageFile, index) => {
                                        const filteredPageName = pageFile?.name?.replace(".json", "");
                                        return (
                                            <TableRow key={`page_${index}`} sx={{ padding: "0", margin: "0" }}>
                                                <TableCell sx={{ padding: "0", margin: "0" }}>
                                                    <Link className="bb-pages-list bb-align-items-center"
                                                        to={`/applications/${appId}/pages/${filteredPageName}`}>
                                                        <Box className="bb-ml-2">{filteredPageName}</Box>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                            }
                            </TableBody>
                        </Table>
                        <TablePagination
                            className="bb-global-table-footer"
                            rowsPerPageOptions={[15, 20, 30, 40]}
                            component="div"
                            count={filteredPages.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Box>
            </Container>
        </FavoriteProvider>
    );
};
