import "./css/bb-media-gallery.css";
import { Grid, Container, Box, Alert } from "@mui/material";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import { MainFolderCard } from "./MainFolderCard";
import { PartnerFolderCard } from "./PartnerFolderCard";
import { PartnerFolderDirectoryList } from ".";
import { useCallback, useContext, useState } from "react";
import useDataClient from "../../axios/dataClient";
import { useAsync } from "../../useAsync";
import { MemberAppContext } from "../../MemberAppContext";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

export const Assets = () => {
    const appContext = useContext(MemberAppContext);
    const { get } = useDataClient();

    const [partnerDirectories, setPartnerDirectories] = useState<PartnerFolderDirectoryList[]>([]);

    const fetchPartnerFolders = useCallback(async () => {
        const value = await get<PartnerFolderDirectoryList[]>("api/asset/partnerFolders");
        return value;
    }, [get]);

    useAsync(fetchPartnerFolders, setPartnerDirectories, []);

    return (<>
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar" sx={{ marginBottom: '4px' }}>
                    <h2 className="bb-m0 bb-p0">Media Library</h2>
                    <FavoriteButton page={`assets/`} displayName={`Media Library`} displayModule={`Manage Your Media`} />
                </Box>
                <Alert className="bb-title-info bb-mb-2" severity="info">
                Welcome to the <b>Media Library</b>. Here you can view and manage all of your <b>Media Assets</b>.
                </Alert>
                <Grid
                    justifyContent="left"
                    container
                    spacing={3}
                    alignItems="stretch"
                    alignContent="center"
                >
                    { !appContext.user.isPartnerUser && (
                        <>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp1">
                                <MainFolderCard
                                    name="Public"
                                    friendlyName={"Public"}
                                    description="Access & Manage your Public Media Files"
                                    parentPath="root"
                                    isPublic={true}
                                    icon={<PublicOutlinedIcon />} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp2">
                                <MainFolderCard
                                    name="Private"
                                    friendlyName={"Private"}
                                    description="Access & Manage your Private Assets"
                                    parentPath="root"
                                    isPublic={false}
                                    icon={<LockOutlinedIcon />} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                                <PartnerFolderCard />
                            </Grid>
                        </>)
                    }
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
                    {appContext.user.isPartnerUser && (
                        <>
                            {partnerDirectories.map((directory) => (
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={directory.path}>
                                    <MainFolderCard
                                        name={directory.path}
                                        friendlyName={directory.name}
                                        description="Access & Manage your Media Files"
                                        parentPath=""
                                        isPublic={true}
                                        icon={<ImageOutlinedIcon />} />
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            </Container>
        </FavoriteProvider>
    </>);
};
