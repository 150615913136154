import { Link } from "react-router-dom";
import {
    Container,
    Box,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    Alert,
    Tooltip,
    Checkbox
} from "@mui/material";
import { toast } from "react-toastify";
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import useDataClient from "../../../axios/dataClient";
import { Booking } from "../../../schemas/eventManagement/bookingSchema";
import { getBookings, updateBooking } from "../../../services/api/BookingManagementService";
import { ResultModel, ResultListModel } from "../../../schemas/eventManagement/resultSchema";
import { errors } from "../../ErrorDisplay";
import { DateDisplay } from "../../shared/DateDisplay";
import { newBooking } from "../../newModuleNames";
import { SearchField } from "../../applications/customWidget/Search/SearchField";
import { EventDetails } from '../schedule/EventDetails';

export const BookingList: React.FC = () => {
    const { get, put } = useDataClient();
    const { eventId, scheduleId } = useParams();

    const [bookings, setBookings] = useState<Booking[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>();
    const [listFilter, setListFilter] = useState("ShowAllWithoutDeleted");
    const [editedBookings, setEditedBookings] = useState<{ [key: string]: boolean }>({});

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Box className="bb-med-search bb-ml-2" sx={{ minWidth: 200, marginLeft: '0px!important' }}>
                        <Select
                            id="filter-select"
                            value={listFilter}
                            onChange={handleFilterChange}
                            variant="outlined"
                            fullWidth
                            sx={{ height: 40 }}
                        >
                            <MenuItem value="ShowAll">Show All</MenuItem>
                            <MenuItem value="ShowAllWithoutDeleted">Hide Deleted Bookings</MenuItem>
                        </Select>
                    </Box>
                    <Tooltip
                        title="Search for a booking by email or booking number"
                        arrow
                        placement="top"
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: '.8rem',
                                    padding: '0.5rem',
                                },
                                '& .MuiTooltip-arrow': {
                                    color: 'black',
                                },
                            },
                        }}>
                        <Box className="bb-med-search" sx={{ paddingLeft: '0px!important', marginLeft: '0px!important' }}>
                            <SearchField
                                searchString={searchString}
                                callSearch={callSearch}
                                timeout={500}
                            />
                        </Box>
                    </Tooltip>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <GridToolbarExport />
                    <Button
                        variant="contained"
                        onClick={handleSaveAndRefresh}
                        startIcon={<SaveOutlinedIcon sx={{ color: '#11a681' }}/>}
                        sx={{ color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                    >
                            Save
                    </Button>
                </Box>
            </GridToolbarContainer>
        );
    };

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    const fetchData = useCallback(async () => {
        if (scheduleId) {
            setLoading(true);
            getBookings<ResultListModel<Booking>>(get)(scheduleId).then((bookingsData) => {
                if (bookingsData?.isSuccess && bookingsData?.data) {
                    setBookings(bookingsData.data);
                } else if (bookingsData?.messages) {
                    errors(bookingsData.messages);
                }
                return;
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [get, scheduleId]);

    const handleCheckboxChange = (id: string, checked: boolean) => {
        setBookings(prevBookings =>
            prevBookings.map(booking =>
                booking.rowKey === id ? { ...booking, attended: checked } : booking
            )
        );
        setEditedBookings(prev => ({
            ...prev,
            [id]: checked
        }));
    };

    const handleSave = async () => {
        if (!scheduleId) {
            return;
        }

        try {
            const updatePromises = Object.entries(editedBookings).map(([bookingId, attended]) => {
                const booking = bookings.find(b => b.rowKey === bookingId);
                if (!booking) {
                    return Promise.resolve();
                }
                const bookingDataToSave: Booking = {
                    ...booking,
                    attended,
                    userName: booking.userName,
                    userEmail: booking.userEmail,
                    rowKey: booking.rowKey
                };
                return updateBooking<ResultModel>(put)(scheduleId, bookingId, bookingDataToSave).then((result) => {
                    if (result.isSuccess) {
                        toast.success("Booking updated");
                    } else if (result?.messages) {
                        errors(result.messages);
                    }
                    return result;
                });
            });
            await Promise.all(updatePromises);
            setEditedBookings({});
            refreshList();
        } catch (error) {
            toast.error("An error occurred while updating the bookings");
        }
    };

    const refreshList = useCallback(() => {
        fetchData();
    }, [fetchData]);

    const handleSaveAndRefresh = async () => {
        await handleSave();
        refreshList();
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const filteredBookings = useMemo(() => {
        return bookings.filter((booking) =>
            (listFilter === "ShowAll" || (booking?.deleted !== true)) &&
            (!searchString ||
                booking.userEmail?.toLowerCase().includes(searchString.toLowerCase()) ||
                booking.bookingNumber?.toLowerCase().includes(searchString.toLowerCase()) ||
                booking.userName?.toLowerCase().includes(searchString.toLowerCase())
            )
        );
    }, [listFilter, bookings, searchString]);

    if (!scheduleId) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    const columns: GridColDef[] = [
        { field: 'bookingNumber', headerName: 'Booking Number', flex: 1, renderCell: (params) =>
            (<Link to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${params.row.rowKey}`}>
                {params.value}
            </Link>)
        },
        { field: 'userName', headerName: 'User Name', flex: 1, renderCell: (params) =>
            params.value
        },
        { field: 'userEmail', headerName: 'User Email', flex: 1 },
        { field: 'timestamp', headerName: 'Last Update Date', flex: 1, renderCell: (params) =>
            <DateDisplay value={new Date(params.value)} dateStyle="short" timeStyle="short" />
        },
        { field: 'deleted', headerName: 'Deleted', flex: 1, renderCell: (params) =>
            params.value ? "Yes" : "No"
        },
        {
            field: 'attended',
            headerName: 'Attended',
            flex: 1,
            renderCell: (params) => (
                <Checkbox
                    checked={!!params.value}
                    onChange={(event) => handleCheckboxChange(params.id as string, event.target.checked)}
                />
            ),
            renderEditCell: (params: GridRenderEditCellParams) => (
                <Checkbox
                    checked={!!params.value}
                    onChange={(event) => handleCheckboxChange(params.id as string, event.target.checked)}
                />
            ),
            editable: true
        },
    ];

    return (
        <Container>
            <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Bookings:</h2>{eventId && <EventDetails eventId={eventId} />}
                </Box>
                <Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                        to={`/eventManagement/events/${eventId}/schedules/${scheduleId}`}>
                        <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                            startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Schedule
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/eventManagement">Event Management</Link>
                <Link to="/eventManagement/events">Events</Link>
                <Link to={`/eventManagement/events/${eventId}/schedules`}>Schedules</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Bookings</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '18px!important' }}
            >
                Welcome to the <b>Bookings Management Page.</b> Here, you can manage all of the bookings for your event.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="h3" className="bb-m0 bb-p0">Bookings Manager</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            onClick={refreshList}
                            startIcon={<RefreshOutlinedIcon sx={{ color: '#11a681' }}/>}
                            disabled={isLoading}
                            sx={{ marginRight: '10px', color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                        >
                            Refresh
                        </Button>
                        <Link
                            className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${newBooking}`}
                        >
                            <Button variant="contained" disabled={isLoading} startIcon={<PersonAddAlt1OutlinedIcon />} sx={{ marginRight: '10px' }}>
                                New Booking
                            </Button>
                        </Link>
                        <Link
                            className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/queue`}
                        >
                            <Button variant="contained" disabled={isLoading} startIcon={<GroupsOutlinedIcon />}>
                                View Waiting Queue
                            </Button>`
                        </Link>
                    </Box>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress /></Box>)
                    : (
                        <Box sx={{ height: 'auto', width: '100%', border: 'transparent!important', borderRadius: '4px', padding: '0px!important' }}>
                            <DataGrid
                                sx={{ border: 'transparent!important' }}
                                rows={filteredBookings}
                                columns={columns}
                                pagination
                                pageSizeOptions={[5, 10, 25]}
                                autoHeight
                                getRowId={(row) => row.rowKey}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    )
                }
            </Box>
        </Container>
    );
};
