import { Box, Button, Alert, Container, Grid, Card, CardContent, Typography } from "@mui/material";

import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { useCallback, useState, useEffect } from "react";

import { IntegrationDialog } from "./intergration-dialog";

export const Connections = () => {

    useEffect(() => {
        document.title = 'Beebot AI - Integrations';
    },[]);

    const [IntergrationDialogOpen, setIntergrationDialogOpen] = useState(false);
    const addIntergrationConnection = useCallback(() => {
        setIntergrationDialogOpen(true);
    }, []);

    const onIntergrationCancel = useCallback(() => {
        setIntergrationDialogOpen(false);
    }, []);

    const onIntergrationSuccess = useCallback(() => {
        setIntergrationDialogOpen(false);
    }, []);

    return (
        <Container>
            <Box>
                <Box className="bb-title-bar"><h2>Integrations</h2></Box>
                <Alert className="bb-title-info" severity="info" sx={{ marginTop: '10px' }}>
                    Manage integrations seamlessly by adding, modifying, or removing integrations with <b>Third-Party Applications & Services</b>.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                                <Typography variant="h3" fontWeight="bold" component="div" sx={{ marginLeft: '10px' }}>Integration Manager</Typography>
                                <Button onClick={addIntergrationConnection} startIcon={<AddCircleOutlineOutlinedIcon />} variant="contained" color="primary">
                                    New Integration
                                </Button>
                            </Box>
                        </Box>
                        <IntegrationDialog
                            isOpen={IntergrationDialogOpen}
                            onCancel={onIntergrationCancel}
                            onSaveSuccess={onIntergrationSuccess}
                        />
                    </Box>
                    <Box sx={{ marginTop: '20px', padding: '25px' }}>
                        <Grid
                            container
                            spacing={3}
                            alignItems="stretch"
                            alignContent="center">

                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp1">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Slack
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Slack Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp2">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Intergration
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Intergration Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Meddbase
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Meddbase Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Fingertips
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Fingertips Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp4">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Mailchimp
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Mailchimp Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp5">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Synergy EIS
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Synergy EIS Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp6">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Synergy FIS
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Synergy FIS Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp7">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        MOSAIC
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your MOSAIC Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp8">
                                <Card className="bb-app-icon">
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LinkOutlinedIcon></LinkOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Sentinel
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your Sentinel Intergration
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
