import { Link, useLocation } from "react-router-dom";

import {
    Container,
    Box,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    Alert,
    Tooltip
} from "@mui/material";

import { SelectChangeEvent } from '@mui/material/Select';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { useState, useEffect, useCallback } from "react";

import { useParams } from "react-router-dom";

import useDataClient from "../../../axios/dataClient";

import { QuestionAnswer } from "../../../schemas/eventManagement/questionAnswerSchema";

import { getQuestionAnswers } from "../../../services/api/QuestionAnswerManagementService";

import { ResultListModel } from "../../../schemas/eventManagement/resultSchema";

import { errors } from "../../ErrorDisplay";

import { DateDisplay } from "../../shared/DateDisplay";
import { SearchField } from "../../applications/customWidget/Search/SearchField";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

interface LocationState {
    questionText?: string;
}

export const QuestionAnswerList: React.FC = () => {
    const location = useLocation();
    const state = location.state as LocationState;
    const { questionText } = state || {};
    const { get } = useDataClient();
    const { eventId, questionId } = useParams();

    const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [filteredQuestionAnswers, setFilteredQuestionAnswers] = useState<QuestionAnswer[]>([]);
    const [searchString, setSearchString] = useState<string>();

    const filterShowAll = "ShowAll";
    const [listFilter, setListFilter] = useState(filterShowAll);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Box className="bb-med-search bb-ml-2" sx={{ minWidth: 200, marginLeft: '0px!important' }}>
                        <Select
                            id="filter-select"
                            value={listFilter}
                            onChange={handleFilterChange}
                            variant="outlined"
                            fullWidth
                            sx={{ height: 40 }}
                        >
                            <MenuItem value="ShowAll">Show All</MenuItem>
                            <MenuItem value="ShowAllWithoutDeleted">Hide Deleted Answers</MenuItem>
                        </Select>
                    </Box>
                    <Tooltip
                        title="Search for an answer by User Email."
                        arrow
                        placement="top"
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: '.8rem',
                                    padding: '0.5rem',
                                },
                                '& .MuiTooltip-arrow': {
                                    color: 'black',
                                },
                            },
                        }}>
                        <Box className="bb-med-search" sx={{ paddingLeft: '0px!important', marginLeft: '0px!important' }}>
                            <SearchField
                                searchString={searchString}
                                callSearch={callSearch}
                                timeout={500}
                            />
                        </Box>
                    </Tooltip>
                </Box>
                <Box>
                    <GridToolbarExport />
                </Box>
            </GridToolbarContainer>
        );
    };

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    useEffect(() => {
        if (!questionAnswers) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        const filtered = questionAnswers.filter((questionAnswer) =>
            (!searchStringUpper || (questionAnswer?.userEmail && questionAnswer?.userEmail.toUpperCase().includes(searchStringUpper))
                && (listFilter === filterShowAll || questionAnswer?.deleted !== true)));
        setFilteredQuestionAnswers(filtered);
    }, [listFilter, questionAnswers, searchString]);

    const fetchData = useCallback(async () => {
        if (questionId) {
            getQuestionAnswers<ResultListModel<QuestionAnswer>>(get)(questionId).then((questionAnswersData) => {
                if (questionAnswersData?.isSuccess && questionAnswersData?.data) {
                    setQuestionAnswers(questionAnswersData.data);
                } else if (questionAnswersData?.messages) {
                    errors(questionAnswersData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [get, questionId]);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    const columns: GridColDef[] = [
        { field: 'userEmail', headerName: 'User Email', flex: 1 },
        { field: 'questionText', headerName: 'Question', flex: 1, valueGetter: () => questionText },
        { field: 'answer', headerName: 'Answer', flex: 1 },
        { field: 'timestamp', headerName: 'Date', flex: 1, renderCell: (params) => (
            params.value ? <DateDisplay value={new Date(params.value)} dateStyle="short" timeStyle="short" /> : null
        ) },
        { field: 'deleted', headerName: 'Deleted', flex: 1, valueGetter: (params: any) => params.value ? "Yes" : "No" },
        { field: 'actions', headerName: 'View Questions', flex: 1, renderCell: (params) => (
            <Button
                variant="contained"
                size="small"
                startIcon={<RemoveRedEyeOutlinedIcon />}
                component={Link}
                to={`/eventManagement/events/${eventId}/questions/${questionId}/questionAnswers/${params.row.rowKey}`}
                state={{ questionText }}
            >
                View
            </Button>
        ) },
    ];

    const rows = filteredQuestionAnswers.map((qa, index) => ({ id: index, ...qa }));

    if (!questionId) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Question Answers</h2>
                </Box>
                <Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                        to={`/eventManagement/events/${eventId}/questions/${questionId}`}>
                        <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                            startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Question
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/eventManagement">Event Management</Link>
                <Link to="/eventManagement/events">Events</Link>
                <Link to={`/eventManagement/events/${eventId}/questions`}>Questions</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Question Answers</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '18px!important' }}
            >
                        Welcome to the <b>Question Answer Management Page.</b> Here, you can view and manage all question answers relating to your event.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box className="bb-flex bb-tal bb-mb-2">
                    <h3 className="bb-m0 bb-p0">Answer Manager</h3>
                    <Button
                        variant="contained"
                        className="bb-ml-auto bb-primary-button"
                        onClick={refreshList}
                        startIcon={<RefreshOutlinedIcon />}
                        disabled={isLoading}
                        sx={{ color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                    >
                        Refresh
                    </Button>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress /></Box>)
                    : (
                        <Box sx={{ height: 'auto', width: '100%', border: 'transparent!important', borderRadius: '4px', padding: '0px!important' }}>
                            <DataGrid
                                sx={{ border: 'transparent!important' }}
                                rows={rows}
                                columns={columns}
                                pagination
                                pageSizeOptions={[5, 10, 25]}
                                autoHeight
                                disableRowSelectionOnClick={true}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    )
                }
            </Box>
        </Container>
    );
};
